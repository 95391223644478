<template>
  <ion-page>
    <Main>
      <template #subHeader>
        <ion-toolbar>
          <ion-row v-if="order" class="ion-padding-p ion-padding-vertical">
            <ion-col size="1" class="ion-no-padding">
              <span>
                <ion-icon
                  @click="back"
                  class="back-arrow"
                  :icon="arrowBack"
                ></ion-icon>
              </span>
            </ion-col>
            <ion-col class="ion-no-padding">
              <ion-row class="ion-no-padding">
                <ion-col size="12" class="ion-no-padding">
                  <p class="title">
                    Orden de Producción {{ $filters.folio(order.id) }}
                  </p>
                </ion-col>
                <ion-col size="12" class="ion-no-padding">
                  <p class="subtitle">
                    Fecha de Solicitud:
                    <span class="ion-primary-color ion-margin-start">
                      {{ $filters.timeFrom(order.date) }}</span
                    >
                  </p>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col size="3" class="ion-text-end">
              <span
                class="status"
                :class="{
                  solicitado: order.status == 'solicitado',
                  produccion: order.status != 'solicitado',
                }"
              >
                {{ $filters.capitalize(order.status) }}
              </span>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </template>
      <template #content>
        <ion-row class="cards-container ion-padding-p  ion-padding-vertical">
          <ion-col class="ion-no-padding">
            <ion-card class="ion-no-padding ion-no-margin">
              <ion-card-content>
                <ion-row class="tab-head">
                  <ion-col size="6">
                    <p>Solicitado</p>
                  </ion-col>
                  <ion-col size="6">
                    <p>
                      Fecha:
                      <span class="ion-primary-color pull-right">
                        {{ moment(order.d_date).format("DD MMM YYYY") }}
                      </span>
                    </p>
                  </ion-col>
                </ion-row>
                <ion-row
                  v-for="(product, p) in order.products"
                  :key="p"
                  class="tab-row"
                >
                  <ion-col size="6">
                    <ion-row class="ion-no-padding">
                      <ion-col size="2">
                        <p>
                          <span class="number"> {{ product.quantity }} </span>
                        </p>
                      </ion-col>
                      <ion-col size="10">
                        <p class="tab-row-sub">
                          {{
                            product.name
                              .replace(product.capacity, "")
                              .replace("Lts.", "")
                          }}
                        </p>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                  <ion-col size="6">
                    <p class="p4 tab-row-sub">{{ product.capacity }} Lts.</p>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col
                    v-for="(materia, m) in order.materia"
                    :key="m"
                    size="12"
                  >
                    <p class="subtitle2 ion-padding-horizontal ">
                      {{ materia.name }}
                      <span class="pull-right"
                        >{{ parseFloat(materia.supplied) }} </span
                      >
                    </p>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </template>
      <template #subFooter>
        <ion-toolbar>
          <ion-row v-if="order" class="ion-padding-p ion-padding-vertical">
            <ion-col size="12" class="ion-no-padding ion-margin-bottom">
              <p class="ion-text-center">
                Verifique que almacén haya entregado los insumos necesarios para
                la producción.
              </p>
            </ion-col>
            <ion-col size="12" class="ion-no-padding">
              <ion-button
                type="submit"
                class="ion-margin-bottom"
                expand="block"
                color="primary"
                @click="confirm"
              >
                Confirmar de Recibido e Iniciar Armado
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </template>
    </Main>
  </ion-page>
</template>

<script>
import useOrder from "@/use/useOrder";
import {
  IonPage,
  IonRow,
  IonCol,
  IonIcon,
  IonCard,
  IonToolbar,
  IonButton,
  IonCardContent,
  alertController,
} from "@ionic/vue";
import {
  arrowBack,
  ellipsisHorizontal,
  ellipsisVertical,
} from "ionicons/icons";
import useApi from "@/use/useApi";
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import Main from "@/components/system/main.vue";
import moment from "moment-business-days";

export default {
  name: "Order detail",
  components: {
    Main,
    IonPage,
    IonRow,
    IonCol,
    IonCard,
    IonToolbar,
    IonButton,
    IonCardContent,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    const { params } = useRoute();
    const { order, getOrder } = useOrder();
    if (!order.value) {
      getOrder(params.id, "armador");
    } else if (Object.keys(order.value).length === 0) {
      getOrder(params.id, "armador");
    }
    const info = ref({});
    const controlers = ref({
      tab: "new",
    });
    const orders = ref([{}]);
    const { post } = useApi();

    function back() {
      router.back();
    }

    async function confirm() {
      const data = await post(
        "app/armador/confirm",
        { id: params.id },
        "Validando..."
      );
      if (data === null) {
        const alert = await alertController.create({
          class: "warning",
          header: "Error",
          message: "Se genero un error en servidor",
        });
        await alert.present();
      } else {
        if (data.error === false) {
          getOrder(params.id, "armador");
        }
      }
    }
    return {
      info,
      order,
      orders,
      controlers,
      arrowBack,
      ellipsisHorizontal,
      ellipsisVertical,
      moment,
      back,
      confirm,
    };
  },
};
</script>

<style>
ion-card {
  border-radius: 15px;
  border: 1px solid var(--ion-color-primary);
  box-shadow: none;
  font-size: 12px;
}
ion-card-title {
  color: var(--ion-color-medium);
  font-size: 14px;;
}
ion-toolbar {
  --background: var(--ion-color-light);
}
ion-button::part(native) {
  text-transform: none;
  font-size: 15px;
}
</style>
