<template>
  <ion-header>
    <ion-toolbar id="head-toolbar">
      <ion-title class="ion-padding">
          <ion-img src="/assets/images/plastik-min.png"></ion-img>
      </ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { IonHeader, IonToolbar, IonTitle, IonImg } from "@ionic/vue";
export default {
  name: "headerApp",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonImg,
  },
  setup() {
    return {};
  },
};
</script>
<style>
ion-toolbar#head-toolbar{
  min-height: 90px;
  --background: #00540B;
  display: flex;
}
ion-img::part(image) {
  height: 50px;
  border-color: aliceblue;
  vertical-align: middle;
}
</style>
