<template>
  <ion-page>
    <template v-if="!parseInt(order.armador)">
      <PreProduction />
    </template>
    <template v-else-if="order.status == 'producción' && order.armado == 0">
      <Production />
    </template>
    <template v-else>
      <PostProduction />
    </template>
  </ion-page>
</template>

<script>
import { IonPage } from "@ionic/vue";
import useOrder from "@/use/useOrder";
import PreProduction from "@/views/system/armador/detail/preProduction.vue";
import Production from "@/views/system/armador/detail/production.vue";
import PostProduction from "@/views/system/armador/detail/postProduction.vue";
import { useRoute } from "vue-router";

export default {
  name: "Order detail",
  components: {
    IonPage,
    PreProduction,
    Production,
    PostProduction,
  },
  setup() {
    const { params } = useRoute();
    const { order, getOrder } = useOrder();
    if (!order.value) {
      getOrder(params.id, 'armador');
    } else if (Object.keys(order.value).length === 0) {
      getOrder(params.id, 'armador');
    }
    return {
      order,
    };
  },
};
</script>

<style></style>
