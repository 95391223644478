import { alertController } from "@ionic/vue";
import { reactive, toRefs } from "vue";
import useApi from "@/use/useApi"; 

const pOrder = reactive({
  order: {},
  products: [],
  materia: [],
  supplies: []
});

export default function() {
    const { post } = useApi();
    function setOrder(order: any){
        pOrder.order = order;
        pOrder.products = order.products;
     }
     async function getOrder(id: any, role: string){
        const data  = await post(
            "app/" + role + "/get",
            { id },
            "Obteniendo información"
          );
          if (data === null) {
            const alert = await alertController.create({
              header: "Error",
              message: "Se genero un error en servidor",
            });
            await alert.present();
          } else {
            if (data.error) {
              const alert = await alertController.create({
                header: "Error",
                message: data.message,
              });
              await alert.present();
            } else {
                if(data.order){
                    setOrder(data.order)
                }              
            }
          }
     }
  return {
    ...toRefs(pOrder),
    setOrder,
    getOrder
  };
}
