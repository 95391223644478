<template>
  <ion-page>
    <Header></Header>
    <slot name="subHeader"></slot>
    <ion-content color="light">
      <slot name="content"></slot>
    </ion-content>
    <slot name="subFooter"></slot>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import Header from "@/components/system/header";

export default {
  name: "Main",
  components: {
    Header,
    IonPage,
    IonContent,
  },
  setup() {
    return {};
  },
};
</script>
<style></style>
